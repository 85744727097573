import Can from "components/Can"
import Schedule from "components/Schedule"
import PageHeader from "components/PageHeader"
import ProductCards from "components/ProductCards"

import { ITEMS } from "./constants"

import "./CheckingAccount.styl"

const CheckingAccount = ({ pageContext: { business } }) => {
  return (
    <Can I="management" the="checkingAccount">
      <PageHeader title="Conta Corrente" variant="medium" />
      <section className="checking-account">
        <Schedule businessId={business.business_id} />
        <ProductCards items={ITEMS} />
      </section>
    </Can>
  )
}

export default CheckingAccount
